import React from "react";

function ChatIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 624 121"
    >
      <path
        fill="url(#paint0_linear_26_122)"
        d="M27.176 71.804C17.945 65.156 6.546 65.488 2 66.485v54.183h619.951V25.93c-12.588 1.218-31.791 5.266-63.988 0-32.519-5.319-34.617 2.327-41.96 12.964-6.665 9.655-23.077 1.33-55.596 12.3-26.144 8.819-38.183-27.259-44.057-36.567-4.196-6.648-19.931-8.31-24.127 0-4.196 11.303-6.923 33.375-9.441 36.566-3.934 4.987-12.23 5.59-18.882 3.657-12.588-3.657-10.49-16.553-10.49-25.596 0-8.643-16.217-8.643-18.881-3.324-10.49 20.942-11.539 52.834-29.372 53.187-13.427.266-17.833-7.978-25.176-15.956-2.754-2.992-12.587-5.053-25.175 0-15.735 6.316-20.98.665-22.029-1.663-1.049-2.327-6.181-14.591-7.343-38.891-1.049-21.94-26.495-20.943-28.323-11.968-6.293 30.915 1.124 72.799-28.322 72.799-16.784 0-16.784-10.995-16.784-19.945 0-7.313-13.637-7.978-17.833-1.995-4.196 5.984-7.343 12.965-20.979 12.965-15.874 0-20.98-8.643-20.98-15.292 0-6.648-2.109-14.604-10.49-14.958-15.735-.665-13.637 22.936-18.882 30.25-4.158 5.799-24.127 5.651-35.665-2.66z"
      ></path>
      <path
        stroke="url(#paint1_linear_26_122)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M2 66.485c4.546-.997 15.945-1.33 25.176 5.319 11.539 8.31 31.507 8.458 35.665 2.66 5.245-7.315 3.147-30.916 18.882-30.251 8.381.354 10.49 8.31 10.49 14.958 0 6.649 5.106 15.292 20.98 15.292 13.636 0 16.783-6.981 20.979-12.965 4.196-5.983 17.833-5.318 17.833 1.995 0 8.95 0 19.945 16.784 19.945 29.446 0 22.029-41.884 28.322-72.799 1.828-8.975 27.274-9.972 28.323 11.968 1.162 24.3 6.294 36.564 7.343 38.891 1.049 2.328 6.294 7.98 22.029 1.663 12.588-5.053 22.421-2.992 25.175 0 7.343 7.978 11.749 16.222 25.176 15.956 17.833-.354 18.882-32.245 29.372-53.187 2.664-5.319 18.881-5.319 18.881 3.324 0 9.043-2.098 21.94 10.49 25.596 6.652 1.932 14.948 1.33 18.882-3.657 2.518-3.19 5.245-25.263 9.441-36.566 4.196-8.31 19.931-6.648 24.127 0 5.874 9.308 17.913 45.386 44.057 36.566 32.519-10.97 48.931-2.644 55.596-12.299 7.343-10.637 9.441-18.283 41.96-12.964 32.197 5.266 51.4 1.218 63.988 0"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_26_122"
          x1="311.976"
          x2="311.811"
          y1="4.654"
          y2="133.629"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E069E4"></stop>
          <stop offset="0.766" stopColor="#E069E4" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_26_122"
          x1="604.118"
          x2="392.75"
          y1="26.261"
          y2="343.889"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8B0090"></stop>
          <stop offset="1" stopColor="#E069E4"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default ChatIcon;
